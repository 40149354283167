/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
require('@lib/iziModal/js/iziModal.js')($); //désolé
import Swiper from 'swiper/js/swiper.min';
// import SplitText from "@lib/gsap-pro/SplitText";
// import ScrollTo from "gsap/ScrollToPlugin";
// import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';
// import 'scrollmagic/scrollmagic/uncompressed/plugins/jquery.ScrollMagic.js';
// import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';
// import ScrollMagic from 'scrollmagic';
// import Scrollbar from 'smooth-scrollbar';

/*
|
| Importing Components
|-----------------------
*/
import CookieManager from '@components/cookie-manager';
// import customGoogleMap from '@components/custom-google-map.js';
// import MaterializeForm from '@components/materialize-form.js';
// import Kira from '@components/kira.js';
import Menu from '@components/menu.js';

/*
|
| Importing Utils
|-------------------
*/
// import '@utils/fa';
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import news from '@pages/news.js';
import animations from '@pages/animations.js';
import sample from '@pages/sample.js';
import contact from '@pages/contact.js';
import archiveProducts from '@pages/archive-products.js';
import singleProducts from '@pages/single-product.js'

/*
|
| Routing
|----------
*/
const routes = new Router([
    {
        'file': animations,
        'dependencies': [app, Menu]
    },
	{
		'file': main, 
		'dependencies': [app, Swiper, CookieManager]
    },
    {
		'file': news, 
		'dependencies': [app],
		'resolve': '#page-news-archive'
    },
    {
        'file': archiveProducts,
        'dependencies': [app],
        'resolve': '#page-catalogue'
    },
    {
        'file': singleProducts,
        'dependencies': [app],
        'resolve': '#page-product'
    },
	{
		'file': sample, 
		'dependencies': [app],
		'resolve': '#page-sample'
    },
    {
        'file': contact,
        'dependencies': [app],
        'resolve': '#page-contact'
    }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

