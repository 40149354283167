/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {

        const headItems = document.querySelectorAll('.head-item'),
              bodyItems = document.querySelectorAll('.body-item');

        headItems.forEach( element => {
            element.addEventListener('click', function(){

                //remove active of all head and body items
                for (let index = 0; index < headItems.length; index++) {
                    bodyItems[index].classList.remove('active')
                    headItems[index].classList.remove('active')
                }

                //add class active item
                this.classList.add('active')

                //find content associate
                let itemId = element.dataset.headId;
                let contentToDisplay = document.querySelector('div[data-body-id="'+itemId+'"]');

                contentToDisplay.classList.add('active')
            })
        })

    }
}
