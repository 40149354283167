/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {

        let xhr = null;
        let taxoId = null;

        /*
        |
        | Clique sur les filtres
        |-------------------------
        */
        $('.menu-cat-item').on('click', function () {

            $('.menu-cat-item').each(function () {
                $(this).removeClass('active');
            })

            $(this).addClass('active');

            taxoId = $(this).data().category;

            triggerAjaxCall();

            //loadAttributesAjax(taxoId, 'product', true);
        })


        /*
        |
        | Clique sur les filtres Attributes
        |------------------------------------
        */
        const $zoneFilters = $('.zone-filters');

        $zoneFilters.find('input[type="checkbox"], input[type="radio"]').on('change', function() { 
            const $item = $(this);
            const $select = $item.closest('.bloc-filter');

            if($item.data('type') === 'radio'){
                $select.find('input[type="checkbox"]').not($item).prop('checked', false)
            }   

            const values = $zoneFilters.serializeArray();
            const datas  = extractDatas(values);

            console.log(datas);

            triggerAjaxCall();

            //loadAttributesAjax(taxoId, datas, 'product', true);
        });


        function triggerAjaxCall(){
            const values = $zoneFilters.serializeArray();
            const datas  = extractDatas(values);

            loadAttributesAjax(datas, 'product', true);
        }


        /*
        |
        | Filtre en Ajax Attributes
        |----------------------------
        */
        function loadAttributesAjax(datas, post, banner) {
            if (xhr !== null) {
                xhr.abort()
            }

            //Enleve le contenu
            document.querySelector('#products-list').innerHTML = "";

            //Ajoute le loader
            document.querySelector('.loading').style.display = "block";

            if(banner){
                document.querySelector('#small-banner-ajax').innerHTML = "";
                document.querySelector('.loading-banner').style.display = "block";
            }

            const route = `/ajax/news/attributes/${post}/`;

            xhr = $.ajax({
                url: route,
                type: 'GET',
                dataType: 'json',
                data: {
                    'post': post,
                    'cat': taxoId,
                    'filters' : datas
                },
                success: (response, status) => {

                    //Rempli le DOM
                    //List products
                    document.querySelector('#products-list').appendChild($(response)[0]);

                    if(banner){
                        document.querySelector('#small-banner-ajax').appendChild($(response)[2]);
                        document.querySelector('.loading-banner').style.display = "none";
                    }

                    //Enlève le loader
                    document.querySelector('.loading').style.display = "none";

                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(response, status, error);
                }
            })
        }

        function extractDatas(values){
            const datas = {};

            values.map(object => {
                const { name, value } = object;

                if(!datas.hasOwnProperty(name)){
                    datas[name] = [];
                }

                datas[object.name].push(value)
            });

            return datas;
        }

    }
}
