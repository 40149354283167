/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper, CookieManager) => {
		/*
		|
		| Constants
		|-----------
		*/
        const 
            $body         = $('body'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose  = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse')
		;

        $body.on('loaderEnd', () => console.log('ended'))


        /*
        |
        | Cookie Manager
        |-----------------
        */
        new CookieManager($cookieBanner, {
            name: 'adveris_cookie_use',
            duration: 30,
            buttons: {
                accept: $cookieAccept,
                refuse: $cookieRefuse,
                close: $cookieClose
            },
            onAccept: () => {
                console.log('accepted')
            },
            onRefuse: () => {
                console.log('refused')
            }
        });


        /*
		|
		| Slider home
		|-----------------
		*/
        var swiperhome = new Swiper($('.swiper-home').find('.swiper-container'), {
            speed: 1000,
            spaceBetween: 0,
            paginationClickable: true,
            loop: true,
            autoplay: {
                delay: 4000,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            slidesPerView: 1
        });



        /*
		|
		| 
		|-----------------
		*/
        var containerFilters = document.querySelectorAll('.deploy-item');

        containerFilters.forEach(element => {
    
            var opened = false;
            var btn = element.querySelector('.deploy-btn')
            var filters = element.querySelector('.deploy-section');
            var arrow = element.querySelector('.arrow-filter');
    
            var tlFilters = new TimelineMax({paused: true});
            tlFilters.from(filters, .4, {height: '0'}, 'start');
            tlFilters.to(arrow, .4, {rotation: 180}, 'start')
    
            btn.addEventListener('click', function(){
                if(!opened){
                    tlFilters.play()
                    opened = true;
                } else {
                    tlFilters.reverse()
                    opened = false;
                }
            })
    
        });

	}
}
